import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

import SocialLinks from '../components/SocialLinks';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const IndexPage = () => (
  <Layout MobileNavClass="black" textClass="text-black">
    <section className="contact-us-page">
      <h2 className="text-center">Contact Us</h2>
      <p className="text-center mx-auto">
        Let's collaborate. We partner with advertisers and storytellers on
        multi-platform campaigns that make an impact.
      </p>
      <div className="container">
        <Form>
          <Form.Group controlId="contactForm.ControlInput1">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" name="firstname" />
          </Form.Group>
          <Form.Group controlId="contactForm.ControlInput2">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" name="lastname" />
          </Form.Group>
          <Form.Group controlId="contactForm.ControlInput3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              name="email"
            />
          </Form.Group>
          <Form.Group controlId="contactForm.ControlInput4">
            <Form.Label>Company Name</Form.Label>
            <Form.Control type="text" name="company" />
          </Form.Group>
          <Form.Group controlId="contactForm.ControlInput5">
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" name="title" />
          </Form.Group>
          <Form.Group controlId="contactForm.ControlTextarea1">
            <Form.Label>Your message</Form.Label>
            <Form.Control as="textarea" rows="3" />
          </Form.Group>
          <Form.Group controlId="contactForm.ControlInput5">
            <Button variant="primary" type="submit" className="btn contact-us">
              Submit
            </Button>
          </Form.Group>
        </Form>
      </div>
    </section>
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
